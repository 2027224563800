import {
  IWardsState,
  LOADING_WARD_DATA,
  LOAD_WARD_DATA,
} from "../actions/voterData";

let initialWardData: IWardsState = {
  loaded: false,
  loading: false,
  wards: [],
  totalOfAllWards: {
    ward: "all",
    dem: 0,
    rep: 0,
    other_party: 0,
    white: 0,
    black: 0,
    hispanic: 0,
    other_race: 0,
    unknown_race: 0,
    male: 0,
    female: 0,
    unknown_sex: 0,
    total: 0,
  },
  genders: ["male", "female", "unknown_sex"],
  races: ["white", "black", "hispanic", "other_race", "unknown_race"],
  parties: ["dem", "rep", "other_party"],
  segmentNameLookup: {
    ward: "Ward",
    dem: "Democrat",
    rep: "Republican",
    other_party: "Other Party",
    white: "White",
    black: "Black",
    hispanic: "Hispanic",
    other_race: "Other Race",
    unknown_race: "Unknown Race",
    male: "Male",
    female: "Female",
    unknown_sex: "Unknown Sex",
    total: "Total",
  },
};

const wards = (state: IWardsState = initialWardData, action: any) => {
  let newState = { ...state };
  switch (action.type) {
    case LOADING_WARD_DATA:
      newState.loading = true;
      return newState;
    case LOAD_WARD_DATA:
      newState.loaded = true;
      newState.loading = false;
      newState.wards = action.payload.wards;
      newState.totalOfAllWards = action.payload.totalOfAllWards;
      return newState;

    default:
      return state;
  }
};

export default wards;
