import axios from "axios";

/*--
Basic object shape:
We really only care about rows
{ 
 rows: wardData[];
 fields: //metadata/types about fields
 total_rows: number;
}

--*/

/*-- this is what a sample row looks like
const sample = {
  the_geom: null,
  the_geom_webmercator: null,
  ward: "WD01",
  dem: 9401,
  rep: 1402,
  other_party: 1705,
  total: 12508,
  white: 4446,
  black: 566,
  hispanic: 393,
  other_race: 650,
  male: 4576,
  female: 4927,
  unknown_sex: 3005,
};

--*/

export interface IWardData {
  ward: string;
  dem: number;
  rep: number;
  other_party: number;
  white: number;
  black: number;
  hispanic: number;
  other_race: number;
  unknown_race: number; //This is missing, we can impute it
  male: number;
  female: number;
  unknown_sex: number;
  total: number;
}

export interface IWardResponse {
  data: {
    rows: IWardData[];
    total_rows: number;
  };
}

export const LOAD_WARD_DATA = "LOAD_WARD_DATA";
export const LOADING_WARD_DATA = "LOADING_WARD_DATA";

export interface IWardsState {
  loaded: boolean;
  loading: boolean;
  wards: IWardData[];
  totalOfAllWards: IWardData;
  genders: string[]; // Use these for advanced visualizations page
  races: string[];
  parties: string[];
  segmentNameLookup: {
    [segment: string]: string;
  };
}

export const fetchWards = () => {
  return async (dispatch: any, getState: any) => {
    if (!getState().wards.loaded && !getState().wards.loading) {
      dispatch({ type: LOADING_WARD_DATA, payload: {} });
      let wardsResponse: IWardResponse = await axios.get(
        "https://phl.carto.com/api/v2/sql?q=SELECT+*+FROM+qualified_voter_listing_2018_primary_by_ward&filename=qualified_voter_listing_2018_primary_by_ward&format=json&skipfields=cartodb_id"
      );

      let wards = wardsResponse.data.rows;

      let totalOfAllWards: IWardData = {
        ward: "all",
        dem: 0,
        rep: 0,
        other_party: 0,
        white: 0,
        black: 0,
        hispanic: 0,
        other_race: 0,
        unknown_race: 0,
        male: 0,
        female: 0,
        unknown_sex: 0,
        total: 0,
      };
      //imputing the unknown race here
      //this fields seems to be the only missing on casual analysis
      wards = wards.map((ward) => {
        ward.unknown_race =
          ward.total -
          ward.white -
          ward.black -
          ward.hispanic -
          ward.other_race;
        return ward;
      });

      //Calcualate total values
      totalOfAllWards = wards.reduce((acc, ward) => {
        acc.dem += ward.dem;
        acc.rep += ward.rep;
        acc.other_party += ward.other_party;
        acc.white += ward.white;
        acc.black += ward.black;
        acc.hispanic += ward.hispanic;
        acc.other_race += ward.other_race;
        acc.unknown_race += ward.unknown_race;
        acc.female += ward.female;
        acc.male += ward.male;
        acc.unknown_sex += ward.unknown_sex;
        acc.total += ward.total;
        return acc;
      }, totalOfAllWards);
      return dispatch({
        type: LOAD_WARD_DATA,
        payload: { wards: wards, totalOfAllWards: totalOfAllWards },
      });
    }
  };
};
