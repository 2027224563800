import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  Container,
  NavLink,
  Nav,
  NavbarBrand,
} from "reactstrap";

const PageWrapper: FC = ({ children }) => {
  // Collapse isOpen State
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();
  return (
    <Container className="p-0" fluid style={{ minHeight: "100vh" }}>
      <Navbar color="dark" dark expand="md" container="fluid">
        <NavbarBrand href="/">David's Demo</NavbarBrand>
        <NavbarToggler
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink to="/" tag={RRNavLink}>
                Core Requirement
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/visualizations" tag={RRNavLink}>
                More Visualizations (for fun)
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      {children}
      <div className="w-100 p-4 text-center">
        <span className="text-muted">Cloud Chimp &copy; 2022</span>
      </div>
    </Container>
  );
};

export default PageWrapper;
