import React, { FC, useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import PageWrapper from "./PageWrapper";
import { IRootState } from "../store/root";
import { fetchWards, IWardData } from "../store/actions/voterData";
import { Helmet } from "react-helmet";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { PieChart } from "react-minimal-pie-chart";

const colorPalette = ["#5faeba", "#5fbfc4", "#e7a095", "#fc7575", "#ea5252"];

const Main: FC = () => {
  const dispatch = useDispatch();
  const [ward, setWard] = useState("all");
  const { wards } = useSelector(
    (state: IRootState) => ({ wards: state.wards }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchWards());
  }, [dispatch]);

  const getValues = (type: string) => {
    let values: any = [];
    if (type === "race") {
      values = ["black", "hispanic", "white", "other_race", "unknown_race"];
    } else if (type === "gender") {
      values = ["male", "female", "unknown_sex"];
    } else if (type === "party") {
      values = ["rep", "dem", "other_party"];
    }

    let selectedWard = wards.totalOfAllWards;
    if (ward !== "all") {
      selectedWard = wards.wards.filter((w) => {
        return w.ward === ward;
      })[0];
    }
    let returnValues = values.map((value: string) => {
      let percent =
        Math.round(
          /* @ts-ignore */
          (selectedWard[value] / selectedWard.total + Number.EPSILON) * 10000
        ) /
          100 +
        "%";
      return [
        value,
        wards.segmentNameLookup[value],
        /* @ts-ignore */
        selectedWard[value],
        percent,
      ];
    });
    return returnValues;
  };

  const defaultLabelStyle = {
    fontSize: "5px",
    fontFamily: "sans-serif",
  };

  return (
    <PageWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Philadelphia Qualified Voter Pie Charts</title>
      </Helmet>
      {wards.loaded ? (
        <Container fluid>
          <h4 className="p-2">
            {" "}
            Philadelphia Qualified Voter Demographic Breakdown{" "}
          </h4>
          <Form>
            <FormGroup>
              <Label for="ward" size="sm" className="w-100">
                Show demographic information for:
                <Input
                  type="select"
                  name="ward"
                  id="ward"
                  value={ward}
                  onChange={(e) => setWard(e.target.value)}
                >
                  <option value="all">All Wards</option>
                  {wards.wards.map((ward) => {
                    return (
                      <option key={ward.ward} value={ward.ward}>
                        {ward.ward}
                      </option>
                    );
                  })}
                </Input>
              </Label>
            </FormGroup>
          </Form>
          <Row className="pt-2">
            <Col sm="12" md="4">
              <Card>
                <CardHeader>Breakdown By Party</CardHeader>
                <CardBody>
                  <PieChart
                    label={({ dataEntry }) =>
                      dataEntry.title + "(" + dataEntry.percent + ")"
                    }
                    labelStyle={(index) => ({
                      fill: "black",
                      fontSize: "3px",
                      fontFamily: "sans-serif",
                    })}
                    radius={40}
                    data={getValues("party").map(
                      (segment: any, index: number) => {
                        return {
                          title: segment[1],
                          value: segment[2],
                          percent: segment[3],
                          color: colorPalette[index],
                        };
                      }
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" md="4">
              <Card>
                <CardHeader>Breakdown By Race</CardHeader>
                <CardBody>
                  <PieChart
                    label={({ dataEntry }) =>
                      dataEntry.title + "(" + dataEntry.percent + ")"
                    }
                    labelStyle={(index) => ({
                      fill: "black",
                      fontSize: "3px",
                      fontFamily: "sans-serif",
                    })}
                    radius={40}
                    data={getValues("race").map(
                      (segment: any, index: number) => {
                        return {
                          title: segment[1],
                          value: segment[2],
                          percent: segment[3],
                          color: colorPalette[index],
                        };
                      }
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" md="4">
              <Card>
                <CardHeader>Breakdown By Gender</CardHeader>
                <CardBody>
                  <PieChart
                    label={({ dataEntry }) =>
                      dataEntry.title + "(" + dataEntry.percent + ")"
                    }
                    labelStyle={(index) => ({
                      fill: "black",
                      fontSize: "3px",
                      fontFamily: "sans-serif",
                    })}
                    radius={40}
                    data={getValues("gender").map(
                      (segment: any, index: number) => {
                        return {
                          title: segment[1],
                          value: segment[2],
                          percent: segment[3],
                          color: colorPalette[index],
                        };
                      }
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        "Loading ..."
      )}
    </PageWrapper>
  );
};

export default Main;
