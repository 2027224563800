import { combineReducers } from "redux";
import { IWardsState } from "./actions/voterData";
import wards from "./reducers/voterData";

export interface IRootState {
  wards: IWardsState;
}

const rootReducer = combineReducers({ wards });

export default rootReducer;
