import { FC } from "react";
import { Main, Visualizations } from "./pages";
import { Route, Routes } from "react-router-dom";

const App: FC = () => {
  return (
    <Routes>
      <Route path="/visualizations" element={<Visualizations />} />
      <Route path="/*" element={<Main />} />
    </Routes>
  );
};

export default App;
