import React, { FC, useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import PageWrapper from "./PageWrapper";
import { IRootState } from "../store/root";
import { fetchWards, IWardData } from "../store/actions/voterData";
import { Helmet } from "react-helmet";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";

const Main: FC = () => {
  const dispatch = useDispatch();
  const [segment, setSegment] = useState("none");
  const { wards } = useSelector(
    (state: IRootState) => ({ wards: state.wards }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchWards());
  }, [dispatch]);

  const getPercent = (ward: IWardData) => {
    if (segment == "none") {
      return "--";
    } else {
      return (
        Math.round(
          /* @ts-ignore */
          (ward[segment] / ward.total + Number.EPSILON) * 10000
        ) /
          100 +
        "%"
      );
    }
  };

  let orderedSegmentsAll = Object.entries(wards.totalOfAllWards).sort(
    ([segmentA, valueA], [segmentB, valueB]) => {
      return valueB - valueA;
    }
  );

  return (
    <PageWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Philadelphia Qualified Voter Listing 2018</title>
      </Helmet>
      {wards.loaded ? (
        <Container fluid>
          <h4 className="p-2"> Philadelphia Qualified Voter Listing 2018 </h4>
          <Row className="pt-2">
            <Col sm="12" md="3">
              <Card>
                <CardHeader>Top Segment of All Voters</CardHeader>
                <CardBody>
                  <b>{wards.segmentNameLookup[orderedSegmentsAll[2][0]]}</b>
                  <br />
                  {orderedSegmentsAll[2][1]} Voters <br />
                  {Math.round(
                    (orderedSegmentsAll[2][1] / wards.totalOfAllWards.total +
                      Number.EPSILON) *
                      10000
                  ) / 100}
                  % of all qualified voters were{" "}
                  {wards.segmentNameLookup[orderedSegmentsAll[2][0]]}
                </CardBody>
              </Card>
              <br />
              <Card>
                <CardHeader>Voters By Segment</CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label for="segment" size="sm" className="w-100">
                        Show voter information for:
                        <Input
                          type="select"
                          name="segment"
                          id="segment"
                          value={segment}
                          onChange={(e) => setSegment(e.target.value)}
                        >
                          <option value="none">Select a Segment</option>
                          {Object.entries(wards.segmentNameLookup)
                            .filter(([segmentName, segmentValue]) => {
                              return !["total", "ward"].includes(segmentName);
                            })
                            .map(([segmentValue, segmentName]) => {
                              return (
                                <option key={segmentValue} value={segmentValue}>
                                  {segmentName}
                                </option>
                              );
                            })}
                        </Input>
                      </Label>
                    </FormGroup>
                  </Form>
                  {segment !== "none" && (
                    <>
                      <b>{wards.segmentNameLookup[segment]}</b>
                      <br />
                      {/* @ts-ignore */}
                      {wards.totalOfAllWards[segment]} Voters <br />
                      {Math.round(
                        /* @ts-ignore */
                        (wards.totalOfAllWards[segment] /
                          wards.totalOfAllWards.total +
                          Number.EPSILON) *
                          10000
                      ) / 100}
                      % of all qualified voters were{" "}
                      {wards.segmentNameLookup[segment]}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" md="9">
              <Card>
                <CardHeader>Breakdown By Wards</CardHeader>
                <CardBody>
                  <Table hover size="small">
                    <thead>
                      <tr>
                        <th></th>
                        <th className="bg-light" colSpan={3}>
                          Party
                        </th>
                        <th colSpan={3}>Gender</th>
                        <th className="bg-light" colSpan={5}>
                          Race
                        </th>
                        <th></th>
                      </tr>
                      <tr>
                        <th>Ward</th>
                        <th className="bg-light">
                          {wards.segmentNameLookup["rep"]}
                        </th>
                        <th className="bg-light">
                          {wards.segmentNameLookup["dem"]}
                        </th>
                        <th className="bg-light">
                          {wards.segmentNameLookup["other_party"]}
                        </th>
                        <th>{wards.segmentNameLookup["male"]}</th>
                        <th>{wards.segmentNameLookup["female"]}</th>
                        <th>{wards.segmentNameLookup["unknown_sex"]}</th>
                        <th className="bg-light">
                          {wards.segmentNameLookup["black"]}
                        </th>
                        <th className="bg-light">
                          {wards.segmentNameLookup["hispanic"]}
                        </th>
                        <th className="bg-light">
                          {wards.segmentNameLookup["white"]}
                        </th>
                        <th className="bg-light">
                          {wards.segmentNameLookup["other_race"]}
                        </th>
                        <th className="bg-light">
                          {wards.segmentNameLookup["unknown_race"]}
                        </th>
                        <th>{wards.segmentNameLookup["total"]}</th>
                        <th>% Selected</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ fontWeight: "bold" }}>
                        <td>ALL</td>
                        <td className="bg-light">
                          {wards.totalOfAllWards.rep}
                        </td>
                        <td className="bg-light">
                          {wards.totalOfAllWards.dem}
                        </td>
                        <td className="bg-light">
                          {wards.totalOfAllWards.other_party}
                        </td>
                        <td>{wards.totalOfAllWards.male}</td>
                        <td>{wards.totalOfAllWards.female}</td>
                        <td>{wards.totalOfAllWards.unknown_sex}</td>
                        <td className="bg-light">
                          {wards.totalOfAllWards.black}
                        </td>
                        <td className="bg-light">
                          {wards.totalOfAllWards.hispanic}
                        </td>
                        <td className="bg-light">
                          {wards.totalOfAllWards.white}
                        </td>
                        <td className="bg-light">
                          {wards.totalOfAllWards.other_race}
                        </td>
                        <td className="bg-light">
                          {wards.totalOfAllWards.unknown_race}
                        </td>
                        <td>{wards.totalOfAllWards.total}</td>
                        <td>{getPercent(wards.totalOfAllWards)}</td>
                      </tr>
                      {wards.wards.map((ward) => {
                        return (
                          <tr key={ward.ward}>
                            <td>{ward.ward}</td>
                            <td className="bg-light">{ward.rep}</td>
                            <td className="bg-light">{ward.dem}</td>
                            <td className="bg-light">{ward.other_party}</td>
                            <td>{ward.male}</td>
                            <td>{ward.female}</td>
                            <td>{ward.unknown_sex}</td>
                            <td className="bg-light">{ward.black}</td>
                            <td className="bg-light">{ward.hispanic}</td>
                            <td className="bg-light">{ward.white}</td>
                            <td className="bg-light">{ward.other_race}</td>
                            <td className="bg-light">{ward.unknown_race}</td>
                            <td>{ward.total}</td>
                            <td>{getPercent(ward)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        "Loading ..."
      )}
    </PageWrapper>
  );
};

export default Main;
